<template>
  <v-container>
    <v-row class="text-center mt-8">
      <v-col>
        <h1>Painel de Administração</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          height="120px"
          background-color="transparent"
          centered
          icons-and-text
          class="mb-8"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab class="white mr-4 px-10" href="#tab-1">
            Demandas
            <v-icon> fas fa-folder-open </v-icon>
          </v-tab>
          <v-tab class="white mr-4 px-10" href="#tab-2">
            Processos
            <v-icon> fas fa-folder </v-icon>
          </v-tab>
          <v-tab class="white px-10" href="#tab-3">
            Agenda
            <v-icon> fas fa-calendar </v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <demands-options :dataOptions="demands" />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <demands-options :dataOptions="lawsuits" />
          </v-tab-item>
          <v-tab-item value="tab-3">
            <demands-options :dataOptions="calendar" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DemandsOptions from "../../components/Admin/DemandsOptions.vue";

export default {
  components: { DemandsOptions },
  data() {
    return {
      tab: 1,
      lawsuits: [
        {
          i: 1,
          table: "materials",
          title: "Materias",
        },
        {
          i: 2,
          table: "actions",
          title: "Ações",
        },
        {
          i: 3,
          table: "objects",
          title: "Tipo de Objetos",
        },
        {
          i: 4,
          table: "districts",
          title: "Comarcas",
        },
        {
          i: 5,
          table: "agencys",
          title: "Orgãos",
        },
        {
          i: 6,
          table: "phases",
          title: "Fases",
        },
        {
          i: 7,
          table: "instances",
          title: "Instancias",
        },
        {
          i: 8,
          table: "units",
          title: "Unidades",
        },
        {
          i: 9,
          table: "rites",
          title: "Ritos",
        },
      ],
      demands: [
        {
          i: 1,
          table: "ranks",
          title: "Classificações",
        },
        {
          i: 2,
          table: "demands/status",
          title: "Status",
        },
        {
          i: 3,
          table: "prioritys",
          title: "Prioridades",
        },
        {
          i: 4,
          table: "agencys",
          title: "Orgãos",
        },
      ],
      calendar: [
        {
          i: 1,
          table: "teste",
          title: "teste",
        },
      ],
    };
  },
};
</script>

<style></style>
