<template>
  <v-list subheader two-line>
    <v-subheader inset>
      <new-option :table="table" />
    </v-subheader>

    <div class="text-center">
      <v-progress-circular
        indeterminate
        v-show="loading"
        color="primary"
      ></v-progress-circular>
    </div>
    <template v-for="item in items">
      <v-divider :key="item.i"></v-divider>
      <v-list-item :key="item.id">
        <v-list-item-avatar>
          <v-icon v-show="!item.active" color="red"> fas fa-eye-slash </v-icon>
          <v-icon v-show="item.active" color="green"> fas fa-eye </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>

          <v-list-item-subtitle
            v-text="item.label || 'Adicione uma descrição'"
          ></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <update-option :table="table" :dataForm="item" />
        </v-list-item-action>
      </v-list-item>
      <v-divider :key="'divider' + item.id"></v-divider>
    </template>
  </v-list>
</template>

<script>
import instanceAxios from "../../plugins/axios";
import NewOption from "../Form/NewOption.vue";
import UpdateOption from "../Form/UpdateOption.vue";
export default {
  components: { NewOption, UpdateOption },
  props: {
    table: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      items: [],
      loading: false,
    };
  },

  mounted() {
    this.$root.$on(`load${this.table}`, () => this.get());
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;

      const { data } = await instanceAxios.get(this.table);

      this.items = data;

      this.loading = false;
    },
  },
};
</script>

<style></style>
