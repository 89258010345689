<template>
  <v-dialog v-model="dialog" persistent width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" v-bind="attrs" v-on="on" @click="dialog = true">
        Editar
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Atualizar</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="itemForm.name"
                label="Nome*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="itemForm.label"
                label="Descrição"
                hint="Uma breve explicação do que significa"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-checkbox
                v-model="itemForm.active"
                label="Ativo"
                color="red"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <small>* Indica campo obrigatorio</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Fechar
        </v-btn>
        <v-btn color="blue darken-1" text @click="updateItem()">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import instanceAxios from "../../plugins/axios";
import ToastService from "../../services/ToastService";
export default {
  props: {
    table: {
      type: String,
      required: true,
    },
    dataForm: {
      type: Object,
      required: true,
    },
  },

  watch: {
    dataForm(newValue) {
      this.itemForm = newValue;
    },
  },

  data() {
    return {
      itemForm: {},
      dialog: false,
    };
  },

  mounted() {
    this.itemForm = this.dataForm;
  },

  methods: {
    async updateItem() {
      try {
        if (this.table != null) {
          await instanceAxios.put(
            `${this.table}/${this.itemForm.id}`,
            this.itemForm
          );

          ToastService("Atualizado com sucesso", 'success');

          this.$root.$emit(`load${this.table}`);

          this.dialog = false;

          this.itemForm = {};

          this.$root.$emit('updateNotify')
        }
      } catch (error) {
        ToastService("Erro ao tentar atualizar", 'error');
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
