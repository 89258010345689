<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab" background-color="cyan" centered dark>
          <v-tabs-slider color="yellow"></v-tabs-slider>

          <v-tab
            v-for="data in dataOptions"
            :key="`options${data.i}`"
            :href="`#tab-${data.i}`"
          >
            {{ data.title }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="data in dataOptions"
            :key="`dataoptions${data.i}`"
            :value="`tab-${data.i}`"
          >
            <lista-options :table="data.table" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListaOptions from "../Listas/ListaOptions.vue";
export default {
  components: { ListaOptions },
  props: {
    dataOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tab: 1,
    };
  },
};
</script>

<style></style>
