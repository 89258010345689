<template>
  <v-dialog width="600px" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" @click="dialog = true">
        Novo
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Cadastrar</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="newItem.name"
                label="Nome*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="newItem.label"
                label="Descrição"
                hint="Uma breve explicação do que significa"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-checkbox
                v-model="newItem.active"
                label="Ativo"
                color="green"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <small>* Indica campo obrigatorio</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Fechar
        </v-btn>
        <v-btn color="blue darken-1" text @click="createItem()"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import instanceAxios from "../../plugins/axios";
import ToastService from "../../services/ToastService";
export default {
  props: {
    table: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      newItem: {
        active: true,
      },
    };
  },

  methods: {
    async createItem() {
      try {
        await instanceAxios.post(`${this.table}`, this.newItem);

        this.dialogCreate = false;

        this.$root.$emit(`load${this.table}`);
        this.newItem = {};
        this.dialog = false;
        ToastService("Cadastrado com sucesso", "success");

        this.$root.$emit("updateNotify");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
